import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogProps,
} from '@chakra-ui/react';
import React from 'react';

export type OpenDialogParams = {
  body: React.ReactElement;
  size?: AlertDialogProps['size'];
  closeOnOverlayClick?: boolean;
};
type DialogState = {
  isOpen: boolean;
  openDialog: (params: OpenDialogParams) => void;
  closeDialog: () => void;
  params?: OpenDialogParams;
};
const initialState: DialogState = {
  isOpen: false,
  openDialog: (params) => {
    throw 'openDialog is not implemented';
  },
  closeDialog: () => {
    throw 'closeDialog is not implemented';
  },
};

const StateContext = React.createContext<DialogState>({ ...initialState });
export const useDialogDispatcher = () => React.useContext(StateContext);

const useDialogDispatcherInternal = () => {
  const [state, setState] = React.useState<
    Pick<DialogState, 'isOpen' | 'params'>
  >({
    isOpen: false,
    params: undefined,
  });
  const openDialog = React.useCallback((params: OpenDialogParams) => {
    setState((state) => {
      return {
        ...state,
        isOpen: true,
        params: params,
      };
    });
  }, []);
  const closeDialog = React.useCallback(() => {
    setState((state) => {
      return {
        ...state,
        isOpen: false,
        params: undefined,
      };
    });
  }, []);
  return {
    state: {
      isOpen: state.isOpen,
      params: state.params,
      openDialog,
      closeDialog,
    },
  };
};

type FocusableObject = Parameters<typeof AlertDialog>[0]['leastDestructiveRef'];
export const DialogContainer: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const {
    state: { isOpen, params, openDialog, closeDialog },
  } = useDialogDispatcherInternal();
  const cancelRef: FocusableObject = React.useRef(null);
  return (
    <StateContext.Provider value={{ isOpen, openDialog, closeDialog, params }}>
      {props.children}

      <AlertDialog
        size={params?.size || 'md'}
        isOpen={isOpen}
        onClose={closeDialog}
        leastDestructiveRef={cancelRef}
        onCloseComplete={closeDialog}
        isCentered={true}
        autoFocus={false}
        closeOnEsc={params?.closeOnOverlayClick || false}
        closeOnOverlayClick={params?.closeOnOverlayClick || false}
      >
        <AlertDialogOverlay>{params?.body}</AlertDialogOverlay>
      </AlertDialog>
    </StateContext.Provider>
  );
};
