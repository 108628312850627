import {
  Box,
  Button,
  HStack,
  Icon,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { MdInfo } from 'react-icons/md';
import { useCheckUnpaid } from '../../hooks/useCheckUnpaid';
import { useStripeConfirmPayment } from '../../hooks/useStripeConfirmPayment';
import { Path, paymentRelatedPaths } from '../../routers/Path';
import { Link } from '../ui/Link';

export const CheckUnpaid: React.FC = () => {
  const location = useLocation();
  const { loading, ...unpaidStatus } = useCheckUnpaid();
  const onClickConfirmPaymentMutation = useStripeConfirmPayment();
  const status = unpaidStatus.status;
  const toast = useToast();

  if (paymentRelatedPaths.includes(location.pathname)) {
    return null;
  }
  if (
    loading ||
    status === undefined ||
    status === 'ok' ||
    status === 'error'
  ) {
    return <Box display={'none'} />;
  }
  if (status === 'ng') {
    return <Navigate to={Path.unpaid} />;
  }
  if (status === 'requires_action') {
    return (
      <HStack
        w={'full'}
        bgColor={'red.100'}
        py={'12px'}
        px={'12px'}
        justifyContent={'space-between'}
      >
        <HStack w={'full'} alignItems={'center'} spacing={'16px'}>
          <Icon as={MdInfo} color={'red.500'} boxSize={'24px'} />

          <Box>
            お使いのアカウントで、ポチコ利用料の
            <Box display={'inline'} fontWeight={'bold'}>
              お支払いに確認が必要です。
            </Box>
          </Box>

          <Button
            isLoading={onClickConfirmPaymentMutation.isPending}
            onClick={() =>
              onClickConfirmPaymentMutation.mutateAsync(
                unpaidStatus.needConfirmation
              )
            }
          >
            確認する
          </Button>
        </HStack>
      </HStack>
    );
  }

  return (
    <HStack
      w={'full'}
      bgColor={'red.100'}
      py={'12px'}
      px={'12px'}
      justifyContent={'space-between'}
    >
      <HStack w={'full'} alignItems={'flex-start'}>
        <Icon as={MdInfo} color={'red.500'} boxSize={'24px'} />
        <VStack alignItems={'flex-start'} spacing={'0.2em'}>
          <Text fontWeight={'bold'}>お支払いが遅延しています</Text>
          <Text color={'gray.700'}>
            ポチコの機能はまもなく停止します。お支払い手続きは
            <Link
              fontWeight={'bold'}
              color={'blue.600'}
              to={unpaidStatus.customerPortalUrl || '#'}
              textDecoration={'underline'}
            >
              決済ポータル
            </Link>
            をご確認ください。
          </Text>
        </VStack>
      </HStack>
    </HStack>
  );
};
