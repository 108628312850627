import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';

import { StripeError } from '@stripe/stripe-js';
import { useForm } from 'react-hook-form';
import CardSection from './CardSection';

type Props = {
  clientSecret: string;
  visible: boolean;
};
export const CardForm: React.FC<Props> = ({ clientSecret, visible }) => {
  const toast = useToast();
  const stripe = useStripe();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ invoiceEmail: string }>({});
  const elements = useElements();
  const [isInputCompleted, setIsInputCompleted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const isButtonEnabled = stripe && isInputCompleted && !loading;

  const _onSubmit = React.useCallback(
    async (input: { invoiceEmail: string }) => {
      if (!stripe || !elements) {
        return;
      }
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        return;
      }
      setLoading(true);
      return stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then(async ({ paymentMethod, error }) => {
          if (error) {
            console.log('[createPaymentMethod error]', error);
            return Promise.reject(error);
          } else if (!paymentMethod) {
            console.log('[createPaymentMethod error]no payment method error');
            return Promise.reject('failed to save payment method');
          } else {
            // return onSubmit({
            //   type: 'card',
            //   paymentMethodId: paymentMethod.id,
            //   invoiceEmail: input.invoiceEmail,
            // });
            return stripe
              .confirmSetup({
                // elements: elements,
                clientSecret: clientSecret,
                confirmParams: {
                  payment_method: paymentMethod.id,
                  return_url: `${
                    GLOBAL_CONFIG.MY_URL.CONSOLE.ORIGIN
                  }/payment/registration/confirmed?invoiceEmail=${encodeURIComponent(
                    input.invoiceEmail
                  )}`,
                },
              })
              .then((e) => {
                console.log('[createPaymentMethod error]', e);
                if (e) {
                  const error = e.error as StripeError;
                  toast({
                    title: 'エラーが発生しました',
                    description: error.message,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                  });
                  return Promise.reject(error);
                }
                return;
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [stripe, elements, clientSecret, toast]
  );

  return (
    <Box w={'full'} hidden={!visible}>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(_onSubmit)}>
        <VStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={'20px'}
        >
          <FormControl isRequired isInvalid={!!errors.invoiceEmail}>
            <FormLabel>請求先メールアドレス</FormLabel>
            <Input
              color={'black'}
              {...register('invoiceEmail', {
                required: true,
              })}
              type={'email'}
            />
            <FormErrorMessage>{errors.invoiceEmail?.message}</FormErrorMessage>
          </FormControl>
          <CardSection
            onChangeCompleteStatus={(isCompleted: boolean) => {
              setIsInputCompleted(isCompleted);
            }}
          />
          <Text>
            ※年は西暦の下2桁を入力してください
            <br />
            ※CVCはカードの裏面に記載されている3桁または4桁のセキュリティコードです
          </Text>

          {GLOBAL_CONFIG.ENV === 'local' && <Box>{clientSecret}</Box>}
          <Button
            type="submit"
            width={{ base: 'full', md: '240px' }}
            variant={'blue-fill'}
            isLoading={loading}
            isDisabled={!isButtonEnabled}
          >
            送信
          </Button>
        </VStack>
      </form>
    </Box>
  );
};
