import { Box, Flex, HStack, StackDivider, VStack } from '@chakra-ui/react';
import React from 'react';

import Footer from './Footer';
import { Logo } from './Logo';
import { LogoutButton } from './LogoutButton';

export const RegistrationLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <VStack
      w={'full'}
      bgColor={'gray.100'}
      pt={{ base: '40px', md: '64px' }}
      gap={{ base: '40px', md: '80px' }}
    >
      <Box w={'full'} px={{ base: '16px', md: 0 }}>
        <VStack
          justifyContent={'center'}
          bgColor={'white'}
          alignItems={'center'}
          w={'full'}
          maxW={{ base: 'full', md: '600px' }}
          mx={'auto'}
          px={{ base: '16px', md: 0 }}
          pt={'32px'}
          pb={'40px'}
          spacing={'32px'}
          divider={<StackDivider borderColor={'gray.200'} />}
        >
          {children}
        </VStack>
      </Box>
      <Footer />
    </VStack>
  );
};

const Header: React.FC<{ showLogoutButton: boolean }> = ({
  showLogoutButton,
}) => {
  return (
    <HStack
      width={'full'}
      borderBottom={'1px solid #DDDDDD'}
      paddingX={4}
      paddingY={4}
      justifyContent={'space-between'}
    >
      <Flex justifyContent={'left'} alignItems={'center'}>
        <Logo width={'135px'} />
      </Flex>
      {showLogoutButton && (
        <Box>
          <LogoutButton />
        </Box>
      )}
    </HStack>
  );
};
