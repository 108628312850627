import React from 'react';

import { ProviderAccount } from '@pochico/shared';
import { useAuthState } from '../../../../context/providerAccount';
import { useDialogDispatcher } from '../../../ui/Dialog';
import { useSignUpSurvey } from '../../signUpSurvey/SignUpSurvey';
import { useOnboardingBookingDialog } from './OnboardingBooking';
import { useOnboardingBookingMenuDialog } from './OnboardingBookingMenu';
import { useOnboardingPublishDialog } from './OnboardingPublish';
import { useOnboardingSpotDialog } from './OnboardingSpot';
import { OnboardingDialogOptions } from './useOnboardingProgress';

export const useOnboardingDialogSequence = (
  providerAccount: ProviderAccount,
  options?: OnboardingDialogOptions
) => {
  const { initialized } = useAuthState();
  const { isOpen, closeDialog } = useDialogDispatcher();
  const [opened, setOpened] = React.useState(false);
  // 公開設定
  const { loading: publishLoading, openOnboardingPublishDialog } =
    useOnboardingPublishDialog({
      providerAccount,
      onCompleted: () => {
        // console.log(`[useOnboardingPublishDialog]OK`);
        closeDialog();
        window.location.reload();
      },
      options,
    });

  // LINEで予約をとってみよう
  const { loading: bookingLoading, openOnboardingBookingDialog } =
    useOnboardingBookingDialog({
      providerAccount,
      onCompleted: () => {
        // console.log(`[useOnboardingBookingDialog]OK`);
        openOnboardingPublishDialog();
      },
      options,
    });

  // 予約枠の作成
  const { loading: spotLoading, openOnboardingSpotDialog } =
    useOnboardingSpotDialog({
      providerAccount,
      onCompleted: () => {
        // console.log(`[useOnboardingSpotDialog]OK`);
        openOnboardingBookingDialog();
      },
      options,
    });

  // 予約メニューの編集
  const { loading: bookingMenuLoading, openOnboardingBookingMenuDialog } =
    useOnboardingBookingMenuDialog({
      providerAccount,
      onCompleted: () => {
        // console.log(`[useOnboardingBookingMenuDialog]OK`);
        openOnboardingSpotDialog();
      },
      options,
    });

  const loading =
    publishLoading || bookingLoading || spotLoading || bookingMenuLoading;

  const open = React.useCallback(() => {
    if (isOpen || !initialized || loading || opened) {
      return;
    }
    openOnboardingBookingMenuDialog();
    setOpened(true);
  }, [initialized, isOpen, loading, openOnboardingBookingMenuDialog, opened]);

  return {
    open,
  };
};
export const OnboardingDialog: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const { open } = useOnboardingDialogSequence(providerAccount, undefined);
  const { needOpen, openSurveyDialog } = useSignUpSurvey();
  React.useEffect(() => {
    if (needOpen) {
      openSurveyDialog();
    } else {
      open();
    }
  }, [open, needOpen, openSurveyDialog]);

  return null;
};
