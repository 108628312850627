import React from 'react';

import { useSetDocumentTitle } from '../../../commons/components/setDocumentTitle';
import { CheckoutForm } from '../../../components/features/payment/CheckoutForm';

const PaymentRegistrationPage = () => {
  useSetDocumentTitle('支払い登録');
  return <CheckoutForm />;
};

export default PaymentRegistrationPage;
