import { ProviderAccount } from '@pochico/shared';
import { useStripe } from '@stripe/react-stripe-js';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { postBotApi } from '../../../dataStore/bot';
import { FirebaseUser } from '../../../firebase/firebaseInit';

export const useFetchSetupIntent = () => {
  const stripe = useStripe();
  const [searchParams] = useSearchParams();
  const clientSecret = searchParams.get('setup_intent_client_secret');
  console.log('useFetchSetupIntent', { stripe, clientSecret });
  const query = useQuery({
    queryKey: ['fetchSetupIntent', clientSecret],
    retry: 0,
    queryFn: React.useCallback(async () => {
      if (!stripe || !clientSecret) {
        return;
      }
      return stripe.retrieveSetupIntent(clientSecret).then((res) => {
        console.log('stripe.retrieveSetupIntent', { clientSecret, res });
        if (res.error) {
          throw res.error;
        }
        if (res.setupIntent) {
          return res.setupIntent;
        } else {
          throw new Error('setupIntent not found.');
        }
      });
    }, [clientSecret, stripe]),
    enabled: Boolean(stripe && clientSecret),
  });
  return query;
};

export const useFetchSetupIntentClientSecret = (
  firebaseUser: FirebaseUser,
  providerAccount: ProviderAccount
) => {
  const mutation = useQuery({
    queryKey: ['fetchSetupIntentClientSecret', providerAccount.id],
    queryFn: async () => {
      return fetchSetupIntentClientSecret(firebaseUser, providerAccount);
    },
  });
  return mutation;
};

const fetchSetupIntentClientSecret = async (
  firebaseUser: FirebaseUser,
  providerAccount: ProviderAccount
) => {
  return postBotApi<{ clientSecret: string }>(
    '/payment/create-setup-intent',
    firebaseUser,
    { providerAccountId: providerAccount.id }
  ).then(async (response) => {
    if (response.ok) {
      return response.body;
    } else {
      console.error({
        message: `fetchSetupIntentClientSecret failed due to ${response.error}`,
        error: response.error,
      });
      throw response.error;
    }
  });
};
