import { createQueryKeys } from '@lukemorales/query-key-factory';
import { ProviderAccount, SignUpSurveyResponse } from '@pochico/shared';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  collection,
  doc,
  getCountFromServer,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import React from 'react';
import CONSTANTS from '../../../commons/constants';
import { db } from '../../../firebase/firebaseInit';

const SIGNUP_SURVEY_DENIED_KEY = 'signUpSurveyDenied';
const queryKey = createQueryKeys('signUpSurvey', {
  check: (providerAccountId: string | undefined) => [providerAccountId || ''],
});
export const useSignUpSurveyResponseMutation = (
  providerAccount: ProviderAccount
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: React.useCallback(
      async ({
        response,
      }: {
        providerAccount: ProviderAccount;
        response: SignUpSurveyResponse;
      }) => {
        const ref = getRef(providerAccount);
        const now = new Date();
        return setDoc(
          doc(ref, response.id),
          {
            ...response,
            createTime: now,
          },
          { merge: true }
        );
      },
      [providerAccount]
    ),
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey.check(providerAccount.id));
    },
  });
  const onDenied = React.useCallback(async () => {
    if (!isNewAccount(providerAccount)) {
      // 過去のアカウントは一度拒否したら対象外とする
      localStorage.setItem(SIGNUP_SURVEY_DENIED_KEY, 'true');
      await queryClient.invalidateQueries(queryKey.check(providerAccount.id));
    }
  }, [providerAccount, queryClient]);

  return { mutation, onDenied };
};

const isNewAccount = (providerAccount: ProviderAccount) =>
  providerAccount.createTime > thresholdDate;

const thresholdDate = new Date('2024-09-26T10:00+09:00');
export type SignUpSurveyResponseCheckResult = {
  done: boolean;
  isNewAccount: boolean;
};
export const useSignUpSurveyResponseCheckQuery = (
  providerAccount: ProviderAccount | undefined
) => {
  const _query = useQuery<undefined | SignUpSurveyResponseCheckResult>({
    queryKey: queryKey.check(providerAccount?.id).queryKey,
    queryFn: React.useCallback(async () => {
      if (!providerAccount) {
        return undefined;
      }
      const _isNewAccount = isNewAccount(providerAccount);
      if (!_isNewAccount) {
        // 過去のアカウントは対象外
        const hasDenied =
          localStorage.getItem(SIGNUP_SURVEY_DENIED_KEY) === 'true';
        if (hasDenied) {
          // 一度回答を拒否した場合は再度出さない
          return { done: true, isNewAccount: _isNewAccount };
          // } else {
          //   return { done: false, isNewAccount: _isNewAccount };
        }
      }

      const ref = getRef(providerAccount);
      const done = await getCountFromServer(
        query(
          ref,
          where('providerAccountId', '==', providerAccount.id),
          where('type', '==', 'signUpSurvey')
        )
      ).then((result) => result.data().count > 0);
      return { done, isNewAccount: _isNewAccount };
    }, [providerAccount]),
    enabled: Boolean(providerAccount),
  });

  return _query;
};

const getRef = (providerAccount: ProviderAccount) => {
  return collection(
    db,
    CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
    providerAccount.id,
    CONSTANTS.COLLECTION.SUB_SURVEYS
  );
};
