import { PaymentDateCalculator, ProviderAccount } from '@pochico/shared';

export const needPayment = (providerAccount: ProviderAccount): boolean => {
  const calc = new PaymentDateCalculator(providerAccount);
  return (
    calc.remainFreeSeconds <= 0 &&
    providerAccount.paymentStatus === 'unregistered'
  );
};

export const getRemainDates = (providerAccount: ProviderAccount) => {
  const calc = new PaymentDateCalculator(providerAccount);
  return {
    freeLastDateString: calc.trialLastDate.format('YYYY年M月D日'),
    remainSeconds: calc.remainFreeSeconds,
  };
};
