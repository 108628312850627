import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Link } from './components/ui/Link';
import { Path } from './routers/Path';

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean; error?: Error }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    Sentry.captureException(error, {
      extra: {
        info,
      },
    });
    console.error('ErrorBoundary', {
      error,
      info,
    });
  }

  render() {
    console.log('ErrorBoundary#render', {
      this: this,
    });
    if (this.state.error) {
      // You can render any custom fallback UI
      return <Fallback error={this.state.error} />;
    }

    return this.props.children;
  }
}

const Fallback = ({ error }: { error: Error }) => {
  return (
    <VStack alignItems={'flex-start'} justifyContent={'center'} p={'16px'}>
      <Box>エラーが発生しました</Box>
      <Box>URL: {window.location.href}</Box>
      <Box color={'red.500'}>エラー内容: {error.message}</Box>
      <pre>{error.stack}</pre>
      <HStack gap={'16px'}>
        <Button
          onClick={() => {
            window.history.back();
          }}
        >
          前のページに戻る
        </Button>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          画面をリロードする
        </Button>
        <Button as={Link} to={Path.logout}>
          一度ログアウトする
        </Button>
      </HStack>
    </VStack>
  );
};
