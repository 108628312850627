import { Box, useToast, VStack } from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Link } from '../components/ui/Link';
import { Path } from '../routers/Path';
import { checkUnpaidQueryKey } from './useCheckUnpaid';

export const useStripeConfirmPayment = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: React.useCallback(
      async (
        needConfirmation:
          | {
              paymentIntentClientSecret: string;
              paymentMethodId: string;
            }
          | undefined
      ) => {
        if (!needConfirmation) {
          return;
        }
        const STRIPE_API_KEY = GLOBAL_CONFIG.STRIPE.STRIPE_API_KEY;
        const stripe = await loadStripe(STRIPE_API_KEY);
        if (!stripe) {
          console.error('stripe is not loaded');
          return;
        }
        return stripe
          .confirmCardPayment(needConfirmation.paymentIntentClientSecret, {
            payment_method: needConfirmation.paymentMethodId,
          })
          .then((result) => {
            if (result.error) {
              throw result;
            } else {
              if (result.paymentIntent?.status === 'succeeded') {
                return;
              }
            }
          })
          .catch((error) => {
            console.error('confirmCardPayment error', {
              error,
              needConfirmation,
            });
            throw error;
          });
      },
      []
    ),

    onSuccess: (x) => {
      toast({
        title: 'カード情報の認証が完了しました',
        status: 'success',
      });
      queryClient.invalidateQueries({ queryKey: checkUnpaidQueryKey._def });
    },
    onError: (error) => {
      const title = (
        <VStack alignItems={'flex-start'}>
          <Box>エラーが発生しました {error.message}</Box>
          <Box>
            支払い方法の変更をする場合は、
            <Link
              textDecoration={'underline'}
              color={'white'}
              to={Path.configuration}
            >
              設定画面
            </Link>
            の「決済ポータル」から行ってください。
          </Box>
        </VStack>
      );

      toast({
        title,
        status: 'error',
        duration: 8000,
      });
    },
  });
  return mutation;
};
