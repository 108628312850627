import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProviderAccount } from '@pochico/shared';
import { EmptyDashboardForUnAttachedAccount } from '../components/features/dashboard/EmptyDashboard';
import BookingConfiguration from '../pages/BookingConfiguration';
import { MailNotificationConfiguration } from '../pages/MailNotificationConfiguration';
import { PasswordChange } from '../pages/PasswordChange';
import BookingFormCreatePage from '../pages/bookingForms/create';
import BookingFormEditPage from '../pages/bookingForms/edit';
import BookingFormListPage from '../pages/bookingForms/list';
import BookingMenuListPage from '../pages/bookingMenus';
import BookingMenuCreatePage from '../pages/bookingMenus/create';
import BookingMenuEditPage from '../pages/bookingMenus/edit';
import BookingMenuShowPage from '../pages/bookingMenus/show';
import { BookingCreatePage } from '../pages/bookings/create';
import { BookingEditPage } from '../pages/bookings/edit';
import { BookingListPage } from '../pages/bookings/list';
import { BookingShowPage } from '../pages/bookings/show';
import BulkAddSpotsPage from '../pages/bulkSpots';
import { ChurnPage } from '../pages/churn/Churn';
import Configuration from '../pages/configuration';
import { DashboardPage } from '../pages/dashboard';
import GcalAuthAlreadyCreated from '../pages/gcal-auth-already-created';
import GcalAuthComplete from '../pages/gcal-auth-complete';
import { BookingLiffIdRegistration } from '../pages/line-oa-link/BookingLiffIdRegistration';
import { LineUserEditPage } from '../pages/lineUsers/edit';
import { LineUserListPage } from '../pages/lineUsers/list';
import { LineUserShowPage } from '../pages/lineUsers/show';
import PaymentRegistrationPage from '../pages/payment/registration';
import PaymentRegistrationCompletePage from '../pages/payment/registration/complete';
import { PaymentMethodConfirmedPage } from '../pages/payment/registration/confirmed';
import { ChangeInvoiceEmailPage } from '../pages/payment/registration/invoiceEmailChange';
import { ChangeInvoiceEmailCompletePage } from '../pages/payment/registration/invoiceEmailChangeComplete';
import { InvoiceEmailVerificationPage } from '../pages/payment/registration/invoiceEmailVerification';
import ReminderConfiguration from '../pages/reminder';
import ReminderConfigurationEdit from '../pages/reminder/edit';
import SharedBookingFormElementListPage from '../pages/sharedBookingForms/list';
import { SpotCreatePage } from '../pages/spots/create';
import { SpotEditPage } from '../pages/spots/edit';
import { SpotListPage } from '../pages/spots/list';
import { SpotShowPage } from '../pages/spots/show';
import ThemeConfiguration from '../pages/theme';
import { Path } from './Path';

// import Segments from './segments/Segments';

export const ConsoleRouters: React.FC<{
  providerAccount: ProviderAccount | undefined;
}> = ({ providerAccount }) => {
  const prefix = React.useMemo(
    () => `/${providerAccount?.id}/${providerAccount?.botId}`,
    [providerAccount]
  );
  if (!providerAccount) {
    return <EmptyDashboardForUnAttachedAccount />;
  }
  return (
    <Routes>
      <Route
        key="/"
        path=""
        element={<DashboardPage providerAccount={providerAccount} />}
      />
      <Route
        key="index.html"
        path="index.html"
        element={<DashboardPage providerAccount={providerAccount} />}
      />
      <Route
        key={Path.gcalAuthComplete}
        path={Path.gcalAuthComplete}
        element={<GcalAuthComplete />}
      />
      <Route
        key={Path.gcalAuthAlreadyCreated}
        path={Path.gcalAuthAlreadyCreated}
        element={<GcalAuthAlreadyCreated />}
      />
      <Route
        key={Path.invoiceEmailVerified}
        path={Path.invoiceEmailVerified}
        element={<InvoiceEmailVerificationPage />}
      />
      <Route
        key={Path.changeInvoiceConfiguration}
        path={Path.changeInvoiceConfiguration}
        element={<ChangeInvoiceEmailPage />}
      />
      <Route
        key={Path.changeInvoiceConfigurationComplete}
        path={Path.changeInvoiceConfigurationComplete}
        element={<ChangeInvoiceEmailCompletePage />}
      />
      <Route
        key={Path.paymentRegistration}
        path={Path.paymentRegistration}
        element={<PaymentRegistrationPage />}
      />
      <Route
        key={Path.paymentMethodConfirmed}
        path={Path.paymentMethodConfirmed}
        element={<PaymentMethodConfirmedPage />}
      />
      <Route
        key={Path.paymentRegistrationComplete}
        path={Path.paymentRegistrationComplete}
        element={<PaymentRegistrationCompletePage />}
      />
      <Route
        key={Path.bookingConfiguration}
        path={Path.bookingConfiguration}
        element={<BookingConfiguration />}
      />
      <Route path={prefix}>
        <Route
          key="/bulkSpots"
          path="bulkSpots"
          element={<BulkAddSpotsPage providerAccount={providerAccount} />}
        />

        <Route
          key="/spot/list"
          path="spot"
          element={<SpotListPage providerAccount={providerAccount} />}
        />
        <Route
          key="/spot/show"
          path="spot/:spotId/show"
          element={<SpotShowPage providerAccount={providerAccount} />}
        />
        <Route
          key="/spot/edit"
          path="spot/:spotId/edit"
          element={<SpotEditPage providerAccount={providerAccount} />}
        />
        <Route
          key="/spot/create"
          path="spot/create"
          element={<SpotCreatePage providerAccount={providerAccount} />}
        />

        <Route
          key="/booking/list"
          path="booking"
          element={<BookingListPage providerAccount={providerAccount} />}
        />
        <Route
          key="/booking/show"
          path="booking/:bookingId/show"
          element={<BookingShowPage providerAccount={providerAccount} />}
        />
        <Route
          key="/booking/edit"
          path="booking/:bookingId/edit"
          element={<BookingEditPage providerAccount={providerAccount} />}
        />
        <Route
          key="/booking/create"
          path="booking/create"
          element={<BookingCreatePage providerAccount={providerAccount} />}
        />

        <Route
          key="/lineUser/list"
          path="lineUser"
          element={<LineUserListPage providerAccount={providerAccount} />}
        />
        <Route
          key="/lineUser/show"
          path="lineUser/:lineUserId/show"
          element={<LineUserShowPage providerAccount={providerAccount} />}
        />
        <Route
          key="/lineUser/edit"
          path="lineUser/:lineUserId/edit"
          element={<LineUserEditPage providerAccount={providerAccount} />}
        />

        <Route
          key="/bookingMenu/list"
          path="bookingMenu"
          element={<BookingMenuListPage providerAccount={providerAccount} />}
        />
        <Route
          key="/bookingMenu/show"
          path="bookingMenu/:bookingMenuId/show"
          element={<BookingMenuShowPage providerAccount={providerAccount} />}
        />
        <Route
          key="/bookingMenu/edit"
          path="bookingMenu/:bookingMenuId/edit"
          element={<BookingMenuEditPage providerAccount={providerAccount} />}
        />
        <Route
          key="/bookingMenu/create"
          path="bookingMenu/create"
          element={<BookingMenuCreatePage providerAccount={providerAccount} />}
        />

        <Route
          key="/bookingForm/list"
          path="bookingForm"
          element={<BookingFormListPage providerAccount={providerAccount} />}
        />
        {/* <Route
          key="/bookingForm/show"
          path="bookingForm/:bookingFormId/show"
          element={<BookingFormShowPage providerAccount={providerAccount} />}
        /> */}
        <Route
          key="/bookingForm/edit"
          path="bookingForm/:bookingFormId/edit"
          element={<BookingFormEditPage providerAccount={providerAccount} />}
        />
        <Route
          key="/bookingForm/create"
          path="bookingForm/create"
          element={<BookingFormCreatePage providerAccount={providerAccount} />}
        />
        <Route
          key="/sharedBookingForm/list"
          path="sharedBookingForm"
          element={
            <SharedBookingFormElementListPage
              providerAccount={providerAccount}
            />
          }
        />
      </Route>
      <Route
        key={Path.configuration}
        path={Path.configuration}
        element={<Configuration />}
      />
      <Route
        key={Path.reminder}
        path={Path.reminder}
        element={<ReminderConfiguration />}
      />
      <Route
        key={Path.reminderEdit}
        path={Path.reminderEdit}
        element={<ReminderConfigurationEdit />}
      />
      <Route
        key={Path.lineLiffIdRegistration}
        path={Path.lineLiffIdRegistration}
        element={<BookingLiffIdRegistration />}
      />
      <Route
        key={Path.theme}
        path={Path.theme}
        element={<ThemeConfiguration />}
      />
      <Route
        key={Path.mailNotification}
        path={Path.mailNotification}
        element={
          <MailNotificationConfiguration providerAccount={providerAccount} />
        }
      />
      <Route
        key="/change-password"
        path="/change-password"
        element={<PasswordChange />}
      />
      <Route key="/churn" path="/churn" element={<ChurnPage />} />
      <Route key="notfound" path="*" element={<>not found</>} />
      {/* <Route
    key="/change-payment-method"
    path="change-payment-method"
    element={
      <ChakraPage>
        <ChangePaymentMethod /> // 請求書払いは受付停止
      </ChakraPage>
    }
  />, */}
      {/* <Route exact path='/segments' render={() => <Segments />} />, */}
    </Routes>
  );
};
