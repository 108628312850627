import {
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Image,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { ProviderAccount } from '@pochico/shared';
import { useDialogDispatcher } from '../../../ui/Dialog';
import { FixedRight } from '../../../ui/Fixed';
import { RightArrowWhite } from '../../../ui/RightArrow';
import { OnboardingDialogOptions } from './useOnboardingProgress';

export const useOnboardingPublishDialog = ({
  providerAccount,
  onCompleted,
  options,
}: {
  providerAccount: ProviderAccount;
  onCompleted: () => void;
  options?: OnboardingDialogOptions;
}) => {
  const state = useDialogDispatcher();

  const openOnboardingPublishDialog = React.useCallback(() => {
    state.openDialog({
      size: '3xl',
      body: <DialogBody onSubmit={onCompleted} />,
    });
  }, [onCompleted, state]);

  return {
    loading: false,
    openOnboardingPublishDialog,
  };
};

const DialogBody: React.FC<{
  onSubmit: () => void;
}> = ({ onSubmit }) => {
  const { closeDialog } = useDialogDispatcher();
  const _onSubmit = React.useCallback(() => {
    closeDialog();
    onSubmit();
  }, [closeDialog, onSubmit]);

  return (
    <AlertDialogContent marginX={'16px'}>
      <AlertDialogHeader>
        <VStack alignItems={'center'} justifyContent={'center'}>
          <Text fontSize="md" color={'blue.600'}>
            [チュートリアル STEP4/4]
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            公開設定をする
          </Text>
          <Text fontSize="md">
            「各種設定」ページでリッチメニューを表示することで
            <br />
            お客様からの予約受付が開始されます
          </Text>
        </VStack>
      </AlertDialogHeader>
      <AlertDialogCloseButton />

      <AlertDialogBody>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          w={'full'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={4}
        >
          <VStack alignItems={'center'}>
            <Image
              height={{ base: '40vh', lg: '30vh' }}
              src={'/assets/onboarding_publish.png'}
            />
            <Text textAlign={'center'}>
              独自のリッチメニューを利用している場合でも
              <br />
              ポチコの機能を組み込むことができます。
              <br />
              詳しくは各種設定ページをご覧ください。
            </Text>
          </VStack>
        </Stack>
      </AlertDialogBody>

      <AlertDialogFooter justifyContent={'center'}>
        <VStack alignItems={'flex-end'} w={'full'}>
          <Button
            w={{ base: 'full', md: '220px' }}
            variant={'gray'}
            onClick={_onSubmit}
          >
            チュートリアルを終了する
            <FixedRight>
              <RightArrowWhite />
            </FixedRight>
          </Button>
        </VStack>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
