import { ProviderAccount } from '@pochico/shared';

import { FirebaseUser } from '../firebase/firebaseInit';
import { postBotApi } from './bot';

// botと共通で揃える型
type CreateCustomerPortalURLRequest = {
  providerAccountId: string;
};

export const fetchCustomerPortalURL = async (
  firebaseUser: FirebaseUser,
  providerAccount: ProviderAccount
) => {
  const request: CreateCustomerPortalURLRequest = {
    providerAccountId: providerAccount.id,
  };
  return postBotApi<{ url: string }>(
    '/payment/customer-portal',
    firebaseUser,
    request
  ).then(async (response) => {
    if (response.ok) {
      return response.body.url;
    } else {
      return Promise.reject(response.error);
    }
  });
};

type CheckUnpaidInvoiceRequest = {
  providerAccountId: string;
};

type CheckUnpaidInvoiceResponse =
  | {
      status: 'ok';
    }
  | {
      status: 'ng' | 'delayed';
      invoiceId: string;
      error: string;
    }
  | {
      status: 'ng' | 'delayed' | 'requires_action';
      invoiceId: string;
      needConfirmation: {
        paymentIntentClientSecret: string;
        paymentMethodId: string;
      };
      error: string;
    };

export const checkUnpaid = async (
  firebaseUser: FirebaseUser,
  providerAccount: ProviderAccount
): Promise<
  | CheckUnpaidInvoiceResponse
  | {
      status: 'failed';
      error: string;
    }
> => {
  const request: CheckUnpaidInvoiceRequest = {
    providerAccountId: providerAccount.id,
  };
  return postBotApi<CheckUnpaidInvoiceResponse>(
    '/payment/check',
    firebaseUser,
    request
  )
    .then(async (response) => {
      if (response.ok) {
        return response.body as CheckUnpaidInvoiceResponse;
      } else {
        console.log({ error: response.error });
        return { status: 'failed', error: response.error } as const;
      }
    })
    .catch((e) => {
      console.log(`checkUnpaid failed due to ${e}`, { error: e });
      return { status: 'failed', error: JSON.stringify(e) };
    });
};
