import {
  Button,
  Checkbox,
  HStack,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  Booking,
  BookingMenu,
  LineUser,
  ProviderAccount,
  isCancelable,
  notUndefinedOrNull,
} from '@pochico/shared';
import React from 'react';
import { Layout } from '../../ui/Layout';
// import { useSavedFilterContext } from '../resourceFilter/SavedFilter';

import dayjs from 'dayjs';
import { atom, useAtom, useAtomValue } from 'jotai';
import {
  BulkActionContextProvider,
  useBulkActionContext,
} from '../../../context/bulkAction';
import { DisplayBooking, DisplayLineUser } from '../../../firebase/types';
import { paginationAtom } from '../../../helpers/perPage';
import {
  useBookingListFilter,
  useDeleteBookings,
  useFetchBookingCount,
  useFetchBookings,
} from '../../../hooks/booking';
import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import { useFetchLineUsers } from '../../../hooks/lineUser';
import { LocationFrom, createLocationFrom } from '../../../hooks/locationState';
import { useIsPC } from '../../../hooks/useIsPC';
import { useShowTooManyResultWarning } from '../../../hooks/useShowTooManyResultWarning';
import { useUrlParamState } from '../../../hooks/useUrlParamState';
import { resourcePath } from '../../../hooks/useUrlPath';
import { BookedUserBadge } from '../../ui/BookingUser';
import { useLoadingOverlayContext } from '../../ui/LoadingOverlay';
import { NavigationButton } from '../../ui/NavigationButton';
import { PaginationIndicator } from '../../ui/PaginationIndicator';
import { SortableTableHeader } from '../../ui/SortableTableHeader';
import { BookingFilterInputs } from './BookingFilterInputs';
import { BookingListCalendarView } from './BookingListCalendarView';
import { BookingListMenu } from './BookingListMenu';

const bookingListPagingAtom = paginationAtom<Booking>({
  page: 1,
  sort: {
    field: 'dateTimeForSort',
    direction: 'asc',
  },
});

const totalCountAtom = atom<number>(0);

export const BookingList: React.FC<{
  providerAccount: ProviderAccount;
}> = ({ providerAccount }) => {
  const isPC = useIsPC();
  const { urlState, setUrlState } = useUrlParamState<{
    tab: 'calendar' | 'list';
  }>(['tab'], {
    defaultValue: {
      tab: isPC ? 'list' : 'calendar',
    },
  });
  const bookingMenusQuery = useFetchBookingMenus({
    providerAccount,
    opts: { includeAll: true },
  });
  const bookingMenus = bookingMenusQuery.data || [];
  const totalCount = useAtomValue(totalCountAtom);
  const [paging] = useAtom(bookingListPagingAtom);

  return (
    <BulkActionContextProvider>
      <Layout
        pageTitle="予約の管理"
        headerAction={
          urlState.tab === 'list' &&
          isPC && (
            <BookingListMenu
              providerAccount={providerAccount}
              bookingMenuList={bookingMenus}
              totalCount={totalCount}
              sort={paging.sort}
            />
          )
        }
      >
        <VStack w={'full'} alignItems={'flex-start'}>
          <NavigationButton
            size={'sm'}
            w={{ base: 'full', md: 'fit-content' }}
            variant="blue-fill"
            to={{
              providerAccount: providerAccount,
              resourceName: 'booking',
              action: 'create',
            }}
          >
            管理者が予約を作成する
          </NavigationButton>
          <VStack
            alignItems={'flex-start'}
            // width={'auto'}
            // maxW={'100vw'}
            w={'full'}
            padding={0}
            gap={0}
            paddingTop={'16px'}
          >
            {bookingMenusQuery.error ? (
              <VStack alignItems={'flex-start'}>
                <Text>エラーが発生しました</Text>
                <Text>{String(bookingMenusQuery.error)}</Text>
              </VStack>
            ) : !bookingMenusQuery.isFetching ? (
              <Tabs
                w={'full'}
                defaultIndex={urlState.tab === 'calendar' ? 0 : 1}
                borderColor={'gray.200'}
                onChange={(index) => {
                  setUrlState({ tab: index === 0 ? 'calendar' : 'list' });
                }}
              >
                <TabList w={'full'} justifyContent={'center'}>
                  <Tab>カレンダー表示</Tab>
                  <Tab>リスト表示</Tab>
                </TabList>
                <TabIndicator
                  mt="-1.5px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
                />

                <TabPanels w={'full'}>
                  <TabPanel w={'full'} justifyContent={'center'}>
                    <BookingListCalendarView
                      providerAccount={providerAccount}
                      bookingMenuList={bookingMenus}
                    />
                  </TabPanel>
                  <TabPanel w={'full'} justifyContent={'center'}>
                    <BookingListView
                      providerAccount={providerAccount}
                      bookingMenuList={bookingMenus}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            ) : (
              <Spinner />
            )}
          </VStack>
        </VStack>
      </Layout>
    </BulkActionContextProvider>
  );
};

const BookingListView: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
}> = ({ providerAccount, bookingMenuList }) => {
  const [paging, setPaging] = useAtom(bookingListPagingAtom);
  const { filter, setFilter } = useBookingListFilter();
  const [totalCount, setTotalCount] = useAtom(totalCountAtom);

  const countQuery = useFetchBookingCount({
    providerAccountId: providerAccount.id,
    filter,
  });
  React.useEffect(() => {
    if (countQuery.data !== undefined && countQuery.data !== totalCount) {
      setTotalCount(countQuery.data);
    }
  }, [countQuery.data, setTotalCount, totalCount]);
  const bookingsQuery = useFetchBookings({
    providerAccount,
    filter,
    perPage: paging.perPage,
    sort: paging.sort,
    page: paging.page || 1,
  });
  const lineUserQuery = useFetchLineUsers({
    providerAccount,
    filter: {
      ids:
        bookingsQuery.data
          ?.map((b) => b.lineUserId)
          .filter(notUndefinedOrNull) || [],
      archived: undefined,
    },
    perPage: bookingsQuery.data?.length || 0,
    sort: { field: 'id', direction: 'asc' },
    page: 1,
    enabled: bookingsQuery.data && bookingsQuery.data.length > 0,
  });
  const onClickColumnHeader = React.useCallback(
    (column: keyof Booking) => {
      // 同じカラムを選択すると昇順降順を入れ替える
      // 別のカラムなら昇順に
      const direction =
        paging.sort.field === column
          ? paging.sort.direction === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc';
      setPaging((paging) => ({
        ...paging,
        page: 1,
        sort: {
          field: column,
          direction,
        },
        lastCursor: undefined,
      }));
    },
    [paging.sort.direction, paging.sort.field, setPaging]
  );
  const locationFrom = React.useMemo(
    () =>
      createLocationFrom(
        resourcePath({
          providerAccount,
          resourceName: 'booking',
          action: 'list',
          urlParams: {
            bookingMenuId: filter.bookingMenuId || '',
            displayDate: JSON.stringify(filter.displayDate),
            tab: 'list',
          },
        })
      ),
    [filter.bookingMenuId, filter.displayDate, providerAccount]
  );

  const isPC = useIsPC();
  useShowTooManyResultWarning(countQuery.data);

  return (
    <VStack
      alignItems={'flex-start'}
      width={'full'}
      padding={0}
      gap={0}
      // paddingTop={'16px'}
    >
      <HStack w={'full'} justifyContent={'space-between'}>
        <BookingFilterInputs
          providerAccount={providerAccount}
          bookingMenus={bookingMenuList}
          filter={filter}
          applyFilter={(newFilter) => {
            setFilter(newFilter);
            setPaging((paging) => ({
              ...paging,
              page: 1,
              lastCursor: undefined,
            }));
          }}
        />
        {!isPC && totalCount > 0 && (
          <BookingListMenu
            providerAccount={providerAccount}
            bookingMenuList={bookingMenuList}
            totalCount={totalCount}
            sort={paging.sort}
          />
        )}
      </HStack>
      {countQuery.error || bookingsQuery.error ? (
        <VStack alignItems={'flex-start'}>
          <Text>エラーが発生しました</Text>
          <Text>{`${countQuery.error || bookingsQuery.error}`}</Text>
        </VStack>
      ) : !bookingsQuery.isFetching && !countQuery.isFetching ? (
        <VStack w={'full'} alignItems={'flex-start'}>
          <BookingListTable
            providerAccount={providerAccount}
            bookingMenuList={bookingMenuList}
            bookings={bookingsQuery.data || []}
            lineUsers={lineUserQuery.data || []}
            onClickColumnHeader={onClickColumnHeader}
            totalCount={countQuery.data || 0}
            locationFrom={locationFrom}
          />
        </VStack>
      ) : (
        <Spinner />
      )}
    </VStack>
  );
};

const BookingListTable: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenuList: BookingMenu[];
  bookings: DisplayBooking[];
  totalCount: number;
  lineUsers: DisplayLineUser[];
  onClickColumnHeader: (column: keyof Booking) => void;
  locationFrom: LocationFrom;
}> = ({
  providerAccount,
  bookingMenuList,
  bookings,
  lineUsers,
  onClickColumnHeader,
  totalCount,
  locationFrom,
}) => {
  const { sort } = useAtomValue(bookingListPagingAtom);
  const { selectedIds, setSelectedIds, clearSelectedIds, bulkAction } =
    useBulkActionContext();
  const onCheckboxClicked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (checked) {
        setSelectedIds(bookings.map((b) => b.id));
      } else {
        clearSelectedIds();
      }
    },
    [bookings, clearSelectedIds, setSelectedIds]
  );
  const deleteMutation = useDeleteBookings(providerAccount);
  const toast = useToast();

  const overlayContext = useLoadingOverlayContext();
  const onClickDelete = React.useCallback(
    () =>
      bulkAction((spotIds: string[]) => {
        return {
          title: `${spotIds.length}件の予約を削除しますか？`,
          body: (
            <>
              <Text>
                元に戻すことはできません。
                <br />
              </Text>
            </>
          ),
          cancelText: 'キャンセル',
          submitText: '削除する',
          onSubmit: async () => {
            overlayContext.onOpen();
            return deleteMutation
              .mutateAsync(spotIds)
              .then(() => {
                clearSelectedIds();
                toast({
                  title: `選択した予約を削除しました`,
                  status: 'success',
                });
              })
              .catch((e) => {
                toast({
                  title: `選択した予約の削除に失敗しました ${e}`,
                  status: 'error',
                });
              })
              .finally(overlayContext.onClose);
          },
        };
      }),
    [bulkAction, clearSelectedIds, deleteMutation, overlayContext, toast]
  );
  const onClickColumnToChangeSort = React.useCallback(
    (column: keyof Booking) => {
      return () => onClickColumnHeader(column);
    },
    [onClickColumnHeader]
  );

  const isPC = useIsPC();
  const [paging, setPaging] = useAtom(bookingListPagingAtom);
  const paginationIndicator = React.useMemo(() => {
    return (
      <PaginationIndicator
        total={totalCount}
        perPage={paging.perPage}
        page={paging.page || 1}
        isLoading={false}
        onChange={setPaging}
      />
    );
  }, [paging.page, paging.perPage, setPaging, totalCount]);
  return (
    <VStack spacing={'8px'} w={'full'}>
      {isPC && (
        <HStack w={'full'} alignItems={'center'}>
          {selectedIds.length > 0 && (
            <Button
              w={'20em'}
              colorScheme="red"
              onClick={onClickDelete}
              isLoading={deleteMutation.isPending}
              isDisabled={selectedIds.length === 0}
            >
              選択した{selectedIds.length}件の予約枠を削除する
            </Button>
          )}
          {paginationIndicator}
        </HStack>
      )}

      <TableContainer w={'full'}>
        <Table variant="pochico-striped">
          <Thead>
            <Tr height={'3rem'} paddingY={'10px'}>
              <Th textAlign={'center'} minWidth={'fit-content'}>
                <Checkbox bg={'white'} onChange={onCheckboxClicked} />
              </Th>

              <SortableTableHeader
                onClick={onClickColumnToChangeSort('dateTimeForSort')}
                sortDirection={
                  sort.field === 'dateTimeForSort' ? sort.direction : undefined
                }
              >
                予約日
              </SortableTableHeader>
              <Th>開始時間</Th>
              <Th>予約メニュー</Th>
              <Th minW={{ base: '15em', md: '20em' }}>予約ユーザー名</Th>
              <Th>キャンセル可否</Th>
              <Th w={'full'}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {bookings.length > 0 ? (
              bookings.map((booking) => {
                return (
                  <BookingTableRow
                    booking={booking}
                    key={booking.id}
                    providerAccount={providerAccount}
                    bookingMenus={bookingMenuList}
                    lineUsers={lineUsers}
                    locationFrom={locationFrom}
                  />
                );
              })
            ) : (
              <Tr>
                <Td colSpan={8}>予約はありません</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {isPC ? (
        paginationIndicator
      ) : selectedIds.length > 0 ? (
        <HStack
          w={'full'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Text fontWeight={'bold'}>{selectedIds.length}件の予約を選択中</Text>

          <Button
            w={'fit-content'}
            colorScheme={'red'}
            onClick={onClickDelete}
            isLoading={deleteMutation.isPending}
            isDisabled={selectedIds.length === 0}
          >
            削除する
          </Button>
        </HStack>
      ) : (
        paginationIndicator
      )}
    </VStack>
  );
};

const BookingTableRow: React.FC<{
  providerAccount: ProviderAccount;
  booking: DisplayBooking;
  bookingMenus: BookingMenu[];
  lineUsers: LineUser[];
  locationFrom: LocationFrom;
}> = ({ providerAccount, booking, bookingMenus, lineUsers, locationFrom }) => {
  const bookingMenu = React.useMemo(
    () => bookingMenus.find((menu) => menu.id === booking.bookingMenu.id),
    [bookingMenus, booking.bookingMenu.id]
  );
  const lineUser = React.useMemo(() => {
    return lineUsers.find((user) => user.id === booking.lineUserId);
  }, [booking.lineUserId, lineUsers]);
  const { selectedIds, setSelectedIds } = useBulkActionContext();
  const onCheckboxClicked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (checked) {
        setSelectedIds([...selectedIds, booking.id]);
      } else {
        setSelectedIds(selectedIds.filter((id) => id !== booking.id));
      }
    },
    [setSelectedIds, booking.id, selectedIds]
  );
  const checked = React.useMemo(() => {
    return selectedIds.includes(booking.id);
  }, [booking.id, selectedIds]);
  const cancelable = React.useMemo(() => {
    return Boolean(bookingMenu && isCancelable(bookingMenu, booking, dayjs()));
  }, [booking, bookingMenu]);

  return (
    <Tr justifyContent={'center'} alignSelf={'center'}>
      <Td textAlign={'center'} minWidth={'fit-content'}>
        <Checkbox
          bg={'white'}
          onChange={onCheckboxClicked}
          isChecked={checked}
        />
      </Td>
      <Td verticalAlign={'middle'}>{booking.displayDate}</Td>
      <Td verticalAlign={'middle'}>{booking.startTime}</Td>
      <Td verticalAlign={'middle'}>{bookingMenu?.name}</Td>
      <Td verticalAlign={'middle'}>
        <BookedUserBadge
          providerAccount={providerAccount}
          booking={booking}
          lineUser={lineUser}
          locationFrom={locationFrom}
        />
      </Td>
      <Td verticalAlign={'middle'}>キャンセル{cancelable ? '可' : '不可'}</Td>
      <Td verticalAlign={'middle'} textAlign={'right'}>
        <NavigationButton
          size={'sm'}
          variant="white-blue"
          locationFrom={locationFrom}
          to={{
            providerAccount: providerAccount,
            resourceName: 'booking',
            resourceId: booking.id,
            action: 'show',
          }}
        >
          詳細
        </NavigationButton>
      </Td>
    </Tr>
  );
};
