import { Badge } from '@chakra-ui/react';
import { BookingMenu, ProviderAccount } from '@pochico/shared';
import React from 'react';

export const BookingMenuStatus: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
}> = ({ providerAccount, bookingMenu }) => {
  if (!providerAccount.needBookingMenu) {
    return null;
  }
  return bookingMenu?.status === 'suspended' ? (
    <Badge
      variant={'subtle'}
      colorScheme={'gray'}
      fontSize={'xs'}
      fontWeight={'normal'}
    >
      下書き
    </Badge>
  ) : (
    <Badge
      variant={'subtle'}
      colorScheme={'green'}
      fontSize={'xs'}
      fontWeight={'normal'}
    >
      公開中
    </Badge>
  );
};
