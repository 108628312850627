import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';

import { Button, HStack, useMediaQuery } from '@chakra-ui/react';
import { useConsoleSidebarContext } from './ConsoleSidebar';

export const HamburgerMenu: React.FC = () => {
  const { isOpen, pageTitle, toggleOpen } = useConsoleSidebarContext();
  const ref = React.useRef<HTMLButtonElement>(null);
  const onClick = React.useCallback(() => {
    toggleOpen();
    if (ref.current) {
      // クリックされたときに半回転するアニメーション
      ref.current.animate(
        isOpen
          ? [{ transform: 'rotate(0)' }, { transform: 'rotate(-180deg)' }]
          : [{ transform: 'rotate(0)' }, { transform: 'rotate(180deg)' }],
        {
          iterations: 1,
          duration: 300,
        }
      );
    }
  }, [isOpen, toggleOpen]);
  const [isPC] = useMediaQuery('(min-width: 768px)');

  return (
    <HStack justifyContent={'center'}>
      <Button
        ref={ref}
        size={'lg'}
        px={'4px'}
        variant={'transparent'}
        w={'full'}
        onClick={onClick}
      >
        {/* {isOpen ? <MdClose size={24} /> : <GiHamburgerMenu size={24} />} */}
        <GiHamburgerMenu size={24} />
      </Button>
      {/* {isPC && <Text whiteSpace={'nowrap'}>{pageTitle}</Text>} */}
    </HStack>
  );
};
