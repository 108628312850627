import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { PaymentDateCalculator, ProviderAccount } from '@pochico/shared';
import dayjs from 'dayjs';
import React from 'react';
import { WebLink } from '../../ui/WebLink';
import { useOnboardingDialogSequence } from '../onboarding/dialog/OnboardingDialog';

export const WelcomeMessage: React.FC<{ providerAccount: ProviderAccount }> = ({
  providerAccount,
}) => {
  const show = React.useMemo(() => {
    const calc = new PaymentDateCalculator(providerAccount, dayjs());
    if (calc.remainFreeSeconds > 0) {
      return true;
    } else {
      return false;
    }
  }, [providerAccount]);
  const { open: showTutorialDialog } = useOnboardingDialogSequence(
    providerAccount,
    { forceOpen: true }
  );
  if (!show) {
    return null;
  }
  return (
    <VStack w={'full'} alignItems={'flex-start'} spacing={'20px'}>
      {/* <Heading size={'lg'}>はじめての方へ</Heading> */}

      <VStack
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        borderColor={'gray.300'}
        borderWidth={'1px'}
        borderRadius={'4px'}
        w={'full'}
        bgColor={'white'}
        padding={4}
        // spacing={'16px'}
      >
        <Text fontWeight={'bold'}>ポチコ 管理者ツールへようこそ！</Text>
        <Box fontSize={'sm'}>
          管理者ツールの使い方、最初のセットアップ、より効果的な使い方など、ポチコについて困ったことがあった場合は
          <WebLink
            target="_blank"
            href="https://help.pochico.app/"
            rel="noopener"
          >
            ヘルプ
          </WebLink>
          をご確認ください。
        </Box>
        <Button
          w={{ base: 'full', md: 'fit-content' }}
          variant={'white-blue'}
          onClick={showTutorialDialog}
        >
          チュートリアル
        </Button>
        {/* <UpcomingInvoiceChangeNotification providerAccount={providerAccount} /> */}
      </VStack>
    </VStack>
  );
};
