import {
  BookingMenu,
  DeadlineSettings,
  ProviderAccount,
  getRandomString,
  nullToUndefined,
  undefinedToNull,
} from '@pochico/shared';
import {
  QueryDocumentSnapshot,
  QuerySnapshot,
  Timestamp,
  UpdateData,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  runTransaction,
  setDoc,
} from 'firebase/firestore';

import CONSTANTS from '../../commons/constants';
import { db } from '../../firebase/firebaseInit';
import { uploadThenGetUrlBookingMenuImage } from '../../firebase/storage';
import {
  BookingMenuCreateParams,
  BookingMenuForConsole,
  BookingMenuUpdateParams,
  FromFSBookingMenu,
  ToFSBookingMenu,
} from '../../firebase/types';

export const getBookingMenuRef = (providerId: string) => {
  return collection(
    db,
    CONSTANTS.COLLECTION.PROVIDER_ACCOUNTS,
    providerId,
    CONSTANTS.COLLECTION.SUB_BOOKING_MENU
  ).withConverter({
    toFirestore(docData: BookingMenu): ToFSBookingMenu {
      return convertBookingMenuToToFSBookingMenu(docData);
    },
    fromFirestore(snap: QueryDocumentSnapshot<FromFSBookingMenu>): BookingMenu {
      const nullableDoc = snap.exists() ? snap.data() : undefined;
      return convertFromFSBookingMenuToBookingMenu(nullableDoc);
    },
  });
};

export const convertBookingMenuToDisplayBookingMenu = (
  menu: BookingMenu
): BookingMenuForConsole => {
  return {
    ...menu,
    displayStatus: menu.status === 'active' ? 'ON' : 'OFF',
  };
};

export const convertBookingMenuFromDisplayStatusToStatus = <
  T extends BookingMenuUpdateParams | BookingMenuForConsole
>(
  menu: T
): T & { status: BookingMenu['status'] } => {
  if ('displayStatus' in menu) {
    return {
      ...menu,
      status: menu.displayStatus === 'ON' ? 'active' : 'suspended',
    };
  }
  return {
    ...menu,
    status: menu.displayStatus === 'ON' ? 'active' : 'suspended',
  };
};

export const convertBookingMenuToToFSBookingMenu = (
  menu: BookingMenu
): ToFSBookingMenu => {
  return {
    ...menu,
    imageUrl: menu.imageUrl ?? null,
    bookingForm: undefinedToNull(menu.bookingForm),
    bookingStart: undefinedToNull(menu.bookingStart),
    bookingEnd: undefinedToNull(menu.bookingEnd),
    cancelEnd: undefinedToNull(menu.cancelEnd),
    customText: undefinedToNull(menu.customText),
  };
};

export const convertFromFSBookingMenuToBookingMenu = (
  menu: FromFSBookingMenu | null | undefined
): BookingMenu => {
  if (
    !menu ||
    typeof menu.id !== 'string' ||
    typeof menu.botId !== 'string' ||
    typeof menu.name !== 'string' ||
    typeof menu.description !== 'string' ||
    typeof menu.displayPriority !== 'number' ||
    !menu.status ||
    typeof menu.isDefault !== 'boolean' ||
    !menu.createTime ||
    !menu.updateTime
  ) {
    throw new Error(
      'invalid args from firestore bookingMenu: ' + JSON.stringify(menu)
    );
  }
  const createTime: Timestamp = menu.createTime as Timestamp;
  const updateTime: Timestamp = menu.updateTime as Timestamp;
  return {
    ...menu,
    id: menu.id,
    botId: menu.botId,
    name: menu.name.trim(),
    description: menu.description.trim(),
    imageUrl: menu.imageUrl ?? undefined,
    displayPriority: menu.displayPriority,
    status: menu.status,
    isDefault: menu.isDefault,
    bookingStart: nullToUndefined(menu.bookingStart),
    bookingEnd: nullToUndefined(menu.bookingEnd),
    customText: nullToUndefined(menu.customText),
    cancelEnd: nullToUndefined(menu.cancelEnd),
    bookingForm: nullToUndefined(menu.bookingForm),
    createTime: createTime.toDate(),
    updateTime: updateTime.toDate(),
  };
};

export const convertBookingMenuToFSBookingMenuForUpdate = (
  menu: Partial<BookingMenu>
): Partial<ToFSBookingMenu> => {
  return {
    ...menu,
    imageUrl: menu.imageUrl ?? null,
    bookingStart: undefinedToNull(menu.bookingStart),
    bookingEnd: undefinedToNull(menu.bookingEnd),
    cancelEnd: undefinedToNull(menu.cancelEnd),
    customText: undefinedToNull(menu.customText),
  };
};

export const createBookingMenu = async (input: {
  providerAccountId: string;
  botId: string; // 本当はいらないが互換性のため残している
  bookingMenuData: BookingMenuCreateParams;
}) => {
  const { providerAccountId, botId, bookingMenuData } = input;
  const menus = await getDocs(getBookingMenuRef(providerAccountId));
  if (
    menus.docs.filter((menu) => menu.data().status === 'suspended').length >=
    CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS
  ) {
    return Promise.reject(
      new Error(
        `下書きメニューを既に${CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS}個作成しているため、これ以上作成することはできません。`
      )
    );
  }

  if (!bookingMenuData.description) {
    bookingMenuData.description = '';
  }

  if (typeof bookingMenuData.name !== 'string' || bookingMenuData.name === '') {
    return Promise.reject(new Error('メニュー名は必須です'));
  }

  if (
    typeof bookingMenuData.displayPriority !== 'number' ||
    Number.isNaN(bookingMenuData.displayPriority)
  ) {
    return Promise.reject(new Error('表示優先度を正しく入力してください。'));
  }
  if (
    bookingMenuData.bookingForm &&
    bookingMenuData.bookingForm.enabled &&
    !bookingMenuData.bookingForm.formId
  ) {
    return Promise.reject(new Error('質問フォームが選択されていません'));
  }

  const bookingMenuId = getRandomString(20);
  const imageUrl = bookingMenuData.imageData?.rawFile
    ? await uploadThenGetUrlBookingMenuImage(
        providerAccountId,
        bookingMenuId,
        bookingMenuData.imageData.rawFile
      )
    : undefined;

  const now = new Date();

  const createData: BookingMenu = {
    id: bookingMenuId,
    botId: botId,
    name: bookingMenuData.name.trim(),
    description: bookingMenuData.description?.trim() || '',
    displayPriority: bookingMenuData.displayPriority,
    imageUrl: imageUrl,
    status: bookingMenuData.status || 'suspended',
    // defaultは既に作られているので管理画面でcreateしない
    isDefault: false,
    bookingStart: bookingMenuData.bookingStart,
    bookingEnd: bookingMenuData.bookingEnd,
    customText: {
      ...bookingMenuData.customText,
    },
    bookingForm: bookingMenuData.bookingForm
      ? {
          enabled: bookingMenuData.bookingForm.enabled,
          formId: bookingMenuData.bookingForm.formId,
          sendBookingSuccess: bookingMenuData.bookingSuccessEnabled || false,
        }
      : undefined,
    cancelEnd: bookingMenuData.cancelEnd,
    createTime: now,
    updateTime: now,
  };

  // 以下の項目以外で未設定(undefined)な項目がある場合はエラー
  const missingKeys = Object.entries(createData)
    .filter(
      ([key, value]) =>
        key !== 'imageUrl' &&
        key !== 'bookingStart' &&
        key !== 'bookingEnd' &&
        key !== 'cancelEnd' &&
        key !== 'bookingForm' &&
        typeof value === 'undefined'
    )
    .map(([key, value]) => key);
  if (missingKeys.length > 0) {
    console.error('createData', {
      createData,
      missingKeys,
    });
    return Promise.reject(
      `以下の項目が未設定です。\n${missingKeys.join(', ')}を設定してください。`
    );
  }

  return setDoc(
    doc(getBookingMenuRef(providerAccountId), createData.id),
    createData,
    { merge: false }
  ).then(() => {
    return { data: createData };
  });
};

export const updateBookingMenu = async (input: {
  providerAccount: ProviderAccount;
  data: BookingMenuUpdateParams;
}) => {
  const { providerAccount, data } = input;
  const paramsData: BookingMenuUpdateParams =
    convertBookingMenuFromDisplayStatusToStatus(data);

  if (typeof paramsData.name !== 'string' || paramsData.name === '') {
    return Promise.reject(new Error('メニュー名は必須です'));
  }
  if (
    typeof paramsData.displayPriority !== 'number' ||
    Number.isNaN(paramsData.displayPriority)
  ) {
    return Promise.reject(new Error('表示優先度を正しく入力してください。'));
  }
  const menus = await fetchBookingMenus(providerAccount);
  if (
    data.displayStatus === 'ON' &&
    menus.filter((menu) => data.id !== menu.id && menu.status === 'active')
      .length >= CONSTANTS.MAX_BOOKING_MENU_ACTIVE_NUMBERS
  ) {
    return Promise.reject(
      new Error(
        `公開中のメニューが既に${CONSTANTS.MAX_BOOKING_MENU_ACTIVE_NUMBERS}個あるため、これ以上公開することはできません。`
      )
    );
  }
  if (
    data.displayStatus === 'OFF' &&
    menus.filter((menu) => data.id !== menu.id && menu.status === 'suspended')
      .length >= CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS
  ) {
    return Promise.reject(
      new Error(
        `下書きメニューを既に${CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS}個作成しているため、これ以上下書きに設定することはできません。`
      )
    );
  }

  if (
    data.bookingForm &&
    data.bookingForm.enabled &&
    !data.bookingForm.formId
  ) {
    return Promise.reject(new Error('質問フォームが選択されていません'));
  }

  const imageData = paramsData.imageData;
  const shouldNoImage = paramsData.shouldNoImage;

  /**
   * NOTE
   * imageDataはローカルからアップロードされたファイル。imageUrlは既に登録されているCloud Storageのurl。shouldNoImageは、画像を削除したいときのパラメータ
   * shouldNoImageがtrueな場合は問答無用でimageUrlをundefined、つまり削除する。
   * imageDataがある場合、新たに追加された画像なので、それを適用する。
   * imageDataがなく、imageUrlがある場合、画像の更新はなく、既存のデータ(imageUrl)を使用する。
   * 両方ない場合は、まだ登録されていない、もしくは削除。
   */
  const imageUrl = await (async () => {
    if (shouldNoImage) {
      return undefined;
    }
    if (imageData && imageData.rawFile) {
      return uploadThenGetUrlBookingMenuImage(
        providerAccount.id,
        data.id,
        imageData.rawFile
      );
    }
    if (paramsData.imageUrl && typeof paramsData.imageUrl === 'string') {
      return paramsData.imageUrl;
    }
    return undefined;
  })();
  const normalizeDeadline = (
    deadline: DeadlineSettings | undefined
  ): DeadlineSettings | undefined => {
    console.log('normalizeDeadline', deadline);
    if (!deadline) {
      return undefined;
    }
    const f = (x: any) => {
      if (typeof x === 'number') {
        return x;
      } else if (typeof x === 'string') {
        const n = Number.parseInt(x);
        return Number.isNaN(n) ? 0 : n;
      } else {
        return 0;
      }
    };
    if (deadline.type === 'relative') {
      return {
        type: 'relative',
        dayBefore: f(deadline.dayBefore),
        hourBefore: f(deadline.hourBefore),
        minuteBefore: f(deadline.minuteBefore),
      };
    } else {
      return {
        type: 'fixed',
        dayBefore: f(deadline.dayBefore),
        time: deadline.time,
      };
    }
  };

  const updateData: UpdateData<BookingMenu> = {
    name: paramsData.name,
    description: paramsData.description ?? '',
    displayPriority: paramsData.displayPriority,
    imageUrl: imageUrl,
    status: paramsData.status,
    bookingStart: normalizeDeadline(paramsData.bookingStart),
    bookingEnd: normalizeDeadline(paramsData.bookingEnd),
    cancelEnd: normalizeDeadline(paramsData.cancelEnd),
    customText: {
      ...paramsData.customText,
    },
    bookingForm: {
      ...paramsData.bookingForm,
      sendBookingSuccess: paramsData.bookingSuccessEnabled || false,
    },
    updateTime: new Date(),
  };
  console.log('updateData', updateData);

  const result = { data: { id: data.id, ...updateData } };
  const bookingMenuRef = getBookingMenuRef(providerAccount.id);
  return runTransaction(db, async (t) => {
    return t
      .get<BookingMenu>(doc(bookingMenuRef, data.id))
      .then((fetchedDoc) => {
        const fetchedMenu = fetchedDoc?.exists()
          ? fetchedDoc.data()
          : undefined;
        if (typeof fetchedMenu === 'undefined') {
          return Promise.reject(new Error('予約メニューが削除されています'));
        }

        // NOTE: fetched.docをそのままupdateの第一引数にいれても型は合うが、そうするとconverterが消えてしまう
        return t.update(
          doc(bookingMenuRef, fetchedDoc.id),
          undefinedToNull(updateData)
        );
      });
  }).then(() => result);
};

export const bulkUpdateBookingMenus = async (
  providerAccount: ProviderAccount,
  bookingMenus: BookingMenuUpdateParams[]
) => {
  const menus = await fetchBookingMenus(providerAccount);
  const bookingMenusToValidate = [
    ...bookingMenus,
    ...menus.filter(
      (menu) => bookingMenus.findIndex((bm) => bm.id === menu.id) === -1
    ),
  ];
  if (
    bookingMenusToValidate.filter(
      (menu) => menu.status === 'active' || menu.displayStatus === 'ON'
    ).length > CONSTANTS.MAX_BOOKING_MENU_ACTIVE_NUMBERS
  ) {
    return Promise.reject(
      new Error(
        `公開中のメニューが既に${CONSTANTS.MAX_BOOKING_MENU_ACTIVE_NUMBERS}個あるため、これ以上公開することはできません。`
      )
    );
  }
  if (
    bookingMenusToValidate.filter(
      (menu) => menu.status === 'suspended' || menu.displayStatus === 'OFF'
    ).length > CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS
  ) {
    return Promise.reject(
      new Error(
        `下書きメニューを既に${CONSTANTS.MAX_BOOKING_MENU_DRAFT_NUMBERS}個作成しているため、これ以上下書きに設定することはできません。`
      )
    );
  }
  await runTransaction(db, async (t) => {
    const ref = getBookingMenuRef(providerAccount.id);
    const now = new Date();
    const ps = bookingMenus.map(async (bookingMenu) => {
      return t.get(doc(ref, bookingMenu.id)).then((bookingMenuDoc) => {
        if (bookingMenuDoc.exists()) {
          // updateManyできるのは限定されたフィールドのみ
          return t.update(doc(ref, bookingMenu.id), {
            displayPriority: bookingMenu.displayPriority,
            status: bookingMenu.status,
            updateTime: now,
          });
        } else {
          return;
        }
      });
    });
    await Promise.all(ps);
  });
  return;
};

export type FetchBookingMenusOptions = {
  includeAll?: boolean;
};
export const fetchBookingMenus = async (
  providerAccount: ProviderAccount,
  opts?: FetchBookingMenusOptions
) => {
  return getDocs(getBookingMenuRef(providerAccount.id))
    .then((snap) => {
      return (snap as QuerySnapshot<BookingMenu>).docs.map((doc) =>
        convertBookingMenuToDisplayBookingMenu(doc.data())
      );
    })
    .then((menus) => {
      // priorityの降順
      return menus
        .filter(
          (menu) =>
            Boolean(opts?.includeAll) || // 全て表示する場合は全て表示
            providerAccount.needBookingMenu || // 予約メニューがONの場合は全て表示
            menu.isDefault // 予約メニューがOFFの場合はデフォルトメニューのみ表示
        )
        .sort((a, b) => {
          if (a.status === 'active') {
            if (b.status === 'active') {
              // どちらも公開状態なら優先度の降順
              const cmp = b.displayPriority - a.displayPriority;
              if (cmp !== 0) {
                return cmp;
              } else {
                // 優先度が同じなら作成日時の昇順
                return a.createTime.getTime() - b.createTime.getTime();
              }
            } else {
              // 公開のほうが優先
              return -1;
            }
          } else {
            if (b.status === 'suspended') {
              // どちらも下書きなら作成日時の昇順
              return a.createTime.getTime() - b.createTime.getTime();
            } else {
              // 公開のほうが優先
              return 1;
            }
          }
        });
    });
};

export const fetchBookingMenu = async (
  providerAccount: ProviderAccount,
  id: string
) => {
  return getDoc(doc(getBookingMenuRef(providerAccount.id), id)).then((snap) => {
    if (snap.exists()) {
      return convertBookingMenuToDisplayBookingMenu(snap.data());
    } else {
      return undefined;
    }
  });
};

export const deleteBookingMenu = async (
  providerAccountId: string,
  bookingMenuId: string
) => {
  if (bookingMenuId === CONSTANTS.DEFAULT_BOOKING_MENU_ID) {
    throw new Error(
      'このメニューはデフォルトのメニューなので、削除することはできません'
    );
  }
  const bookingMenuRef = getBookingMenuRef(providerAccountId);
  await deleteDoc(doc(bookingMenuRef, bookingMenuId));
  return;
};
