import * as React from 'react';

import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { useAuthState } from '../context/providerAccount';
import {
  checkUnpaid,
  fetchCustomerPortalURL,
} from '../dataStore/paymentRepository';

export type UnpaidStatus =
  | {
      status: 'ok';
      customerPortalUrl?: never;
    }
  | {
      status: 'delayed' | 'ng';
      customerPortalUrl?: string;
    }
  | {
      status: 'requires_action';
      customerPortalUrl?: string;
      needConfirmation: {
        paymentIntentClientSecret: string;
        paymentMethodId: string;
      };
    }
  | {
      status: 'error';
      customerPortalUrl?: never;
    };
export const checkUnpaidQueryKey = createQueryKeys('checkUnpaid', {
  fetch: (providerAccountId: string | undefined) => [providerAccountId || ''],
});
export const useCheckUnpaid = () => {
  const { firebaseUser, providerAccount, initialized } = useAuthState();
  console.log('useCheckUnpaid', { firebaseUser, providerAccount, initialized });
  const query = useQuery({
    queryKey: checkUnpaidQueryKey.fetch(providerAccount?.id).queryKey,
    queryFn: React.useCallback(async (): Promise<UnpaidStatus | undefined> => {
      if (!firebaseUser || !providerAccount) {
        return;
      }
      return checkUnpaid(firebaseUser, providerAccount).then(async (result) => {
        if (result.status === 'failed') {
          // 何かしらでエラーなのでここではスルー
          // navigate(Path.logout);
          return { status: 'error' };
        } else if (result.status === 'ok') {
          return {
            status: 'ok',
            customerPortalUrl: undefined,
          };
        }

        const customerPortalUrl = await fetchCustomerPortalURL(
          firebaseUser,
          providerAccount
        );
        if (result.status === 'requires_action') {
          return {
            status: result.status,
            customerPortalUrl,
            needConfirmation: result.needConfirmation,
          };
        } else {
          return {
            status: result.status,
            customerPortalUrl,
          };
        }
      });
    }, [firebaseUser, providerAccount]),
    enabled: Boolean(initialized && firebaseUser && providerAccount),
  });

  if (query.isLoading) {
    return { loading: true, status: undefined };
  }
  if (query.data) {
    if (query.data.status === 'requires_action') {
      return {
        loading: false,
        status: query.data.status,
        customerPortalUrl: query.data.customerPortalUrl,
        needConfirmation: query.data.needConfirmation,
      };
    }
    return {
      loading: false,
      status: query.data?.status,
      customerPortalUrl: query.data?.customerPortalUrl,
    };
  }
  return {
    loading: true,
    status: undefined,
  };
};
