import {
  Box,
  HStack,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import {
  BookingMenu,
  LineUser,
  ProviderAccount,
  bookingAvailableTime,
  dateTimeStringWithWeekDay,
  isNotNullOrUndefined,
  spotStatus,
} from '@pochico/shared';
import React from 'react';

import dayjs from 'dayjs';
import CONSTANTS from '../../../commons/constants';
import { DisplayBooking, DisplaySpot } from '../../../firebase/types';
import { useFetchBookings } from '../../../hooks/booking';
import { useFetchBookingMenus } from '../../../hooks/bookingMenus';
import { useFetchLineUsers } from '../../../hooks/lineUser';
import { LocationFrom, createLocationFrom } from '../../../hooks/locationState';
import { useFetchSpot } from '../../../hooks/spots';
import { resourcePath } from '../../../hooks/useUrlPath';
import { BookedUserBadge } from '../../ui/BookingUser';
import { Layout } from '../../ui/Layout';
import { NavigationButton } from '../../ui/NavigationButton';
import { spotStatusText } from './spotStatus';

export const SpotShow: React.FC<{
  providerAccount: ProviderAccount;
  id: string;
}> = ({ providerAccount, id }) => {
  const query = useFetchSpot({ providerAccount, id });
  const bookingMenuQuery = useFetchBookingMenus({ providerAccount });
  const spot = query.data;
  const bookings = useFetchBookings({
    providerAccount,
    filter: {
      spotId: id,
    },
    perPage: spot?.maxBookings || CONSTANTS.PAGINATION_PER_PAGE_DEFAULT,
    sort: { field: 'dateTimeForSort', direction: 'asc' },
    page: 1,
  });
  const locationFrom = React.useMemo(
    () =>
      createLocationFrom(
        resourcePath({
          resourceName: 'spot',
          action: 'show',
          providerAccount,
          resourceId: id,
        })
      ),
    [id, providerAccount]
  );
  const lineUsers = useFetchLineUsers({
    providerAccount,
    filter: {
      archived: undefined,
      ids:
        bookings.data
          ?.map((booking) => booking.lineUserId)
          .filter(isNotNullOrUndefined) || [],
    },
    perPage: bookings.data?.length || 10,
    sort: { field: 'id', direction: 'asc' },
    page: 1,
  });
  const bookingMenu = React.useMemo(
    () =>
      (bookingMenuQuery.data || []).find(
        (menu) => menu.id === query.data?.bookingMenuId
      ),
    [bookingMenuQuery.data, query.data?.bookingMenuId]
  );
  const isLoading =
    query.isLoading || bookings.isLoading || bookingMenuQuery.isLoading;

  return (
    <Layout
      pageTitle="予約枠の詳細"
      hasBackButton={{
        resourceName: 'spot',
        providerAccount,
        action: 'list',
      }}
      headerAction={
        query.data && (
          <NavigationButton
            size={'sm'}
            variant={'blue-fill'}
            isDisabled={Boolean(
              query.data?.maxBookings &&
                bookings.data?.length &&
                bookings.data.length >= query.data.maxBookings
            )}
            locationFrom={locationFrom}
            to={{
              resourceName: 'booking',
              action: 'create',
              providerAccount: providerAccount,
              urlParams: {
                spotId: query.data?.id || '',
              },
            }}
          >
            <Text>予約を登録する</Text>
          </NavigationButton>
        )
      }
    >
      {isLoading ? (
        <Spinner />
      ) : query.data && bookingMenu ? (
        <VStack alignItems={'flex-start'} width={'full'} spacing={'20px'}>
          <SpotShowCard
            providerAccount={providerAccount}
            bookingMenu={bookingMenu}
            spot={query.data}
            bookings={bookings.data || []}
          />
          <Heading as={'h2'} size={'lg'}>
            予約一覧
          </Heading>
          <BookingList
            providerAccount={providerAccount}
            bookings={bookings.data || []}
            lineUsers={lineUsers.data || []}
            locationFrom={locationFrom}
          />
        </VStack>
      ) : query.data === undefined ? (
        <Box>データが見つかりません</Box>
      ) : bookingMenu === undefined ? (
        <Box>予約メニューが削除されています</Box>
      ) : (
        <Spinner />
      )}
    </Layout>
  );
};

const SpotShowCard: React.FC<{
  providerAccount: ProviderAccount;
  bookingMenu: BookingMenu;
  spot: DisplaySpot;
  bookings: DisplayBooking[];
}> = ({ providerAccount, bookingMenu, spot, bookings }) => {
  const now = React.useMemo(() => dayjs(), []);
  const { statusText, availableTime } = React.useMemo(() => {
    const s = spotStatus(providerAccount, bookingMenu, spot, now);
    const { bookingStart: start, bookingEnd: end } = bookingAvailableTime(
      bookingMenu,
      spot
    );
    // 想定外に不正なデータが入っている場合に画面が白くならないようにしておく
    // if ((!start || !start.isValid()) && (!end || !end.isValid())) {
    //   return {
    //     statusText: spotStatusText(s),
    //     availableTime: undefined,
    //   };
    // }
    const availableTime =
      start && end
        ? `${dateTimeStringWithWeekDay(start)} ~ ${dateTimeStringWithWeekDay(
            end
          )}`
        : start
        ? `${dateTimeStringWithWeekDay(start)} ~`
        : end
        ? `~ ${dateTimeStringWithWeekDay(end)}`
        : undefined;
    return {
      statusText: spotStatusText(s),
      availableTime,
    };
  }, [bookingMenu, now, providerAccount, spot]);
  return (
    <HStack
      w={'full'}
      borderWidth={'1px'}
      borderColor={'gray.300'}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      p={'16px'}
      borderRadius={'4px'}
      spacing={'4px'}
    >
      <VStack w={'full'} alignItems={'flex-start'}>
        <Text fontSize={'sm'} color={'gray.800'}>
          {spot.displayDate} {spot.startTime}~
        </Text>
        <Text fontSize={'2xl'} fontWeight={'bold'}>
          {bookingMenu.name}
        </Text>
        <HStack spacing={'8px'} alignItems={'baseline'}>
          <Text fontSize={'xl'} fontWeight={'bold'} color={'gray.600'}>
            枠数：{spot.maxBookings}
          </Text>
          <Text fontSize={'sm'} color={'gray.500'}>
            / 現在の予約数：{bookings.length}件
          </Text>
        </HStack>
        <HStack spacing={'8px'} alignItems={'baseline'}>
          <Text fontSize={'xl'} fontWeight={'bold'} color={'gray.600'}>
            受付状況：{statusText}
          </Text>
          {availableTime && (
            <Text fontSize={'md'} color={'gray.600'}>
              / 予約受付期間：{availableTime}
            </Text>
          )}
        </HStack>
      </VStack>
      <HStack alignSelf={'flex-end'}>
        <NavigationButton
          size={'sm'}
          variant={'white-blue'}
          to={{
            resourceName: 'spot',
            action: 'edit',
            providerAccount,
            resourceId: spot.id,
          }}
        >
          編集
        </NavigationButton>
      </HStack>
    </HStack>
  );
};

const BookingList: React.FC<{
  providerAccount: ProviderAccount;
  bookings: DisplayBooking[];
  lineUsers: LineUser[];
  locationFrom: LocationFrom;
}> = ({ providerAccount, bookings, lineUsers, locationFrom }) => {
  if (bookings.length === 0) {
    return (
      <Box
        w={'full'}
        alignItems={'flex-start'}
        borderWidth={'1px'}
        borderColor={'gray.100'}
        p={'16px'}
        fontSize={'md'}
      >
        予約はありません
      </Box>
    );
  }
  return (
    <VStack w={'full'} alignItems={'flex-start'} spacing={'16px'}>
      <Box
        w={'full'}
        alignItems={'flex-start'}
        borderWidth={'1px'}
        borderColor={'gray.100'}
        fontSize={'xs'}
      >
        <TableContainer w={'full'}>
          <Table variant="pochico-striped">
            <Thead h={'40px'}>
              <Tr>
                <Th w={'max-content'}>予約ユーザー名</Th>
                <Th>予約メモ</Th>
                <Th w={'20px'}>操作</Th>
              </Tr>
            </Thead>
            <Tbody>
              {bookings.map((booking) => (
                <Tr key={booking.id}>
                  <Td maxH={'40px'} h={'40px'}>
                    <BookedUserBadge
                      booking={booking}
                      locationFrom={locationFrom}
                      lineUser={
                        booking.lineUserId
                          ? lineUsers.find(
                              (user) => booking.lineUserId === user.id
                            )
                          : undefined
                      }
                      providerAccount={providerAccount}
                    />
                  </Td>
                  <Td>{booking.providerMemo}</Td>
                  <Td>
                    <NavigationButton
                      size={'sm'}
                      variant="blue-fill"
                      locationFrom={locationFrom}
                      to={{
                        providerAccount: providerAccount,
                        resourceName: 'booking',
                        resourceId: booking.id,
                        action: 'show',
                      }}
                    >
                      詳細
                    </NavigationButton>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </VStack>
  );
};
