import './statics/index.css';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { DialogContainer } from './components/ui/Dialog';
import { LoadingOverlayContextProvider } from './components/ui/LoadingOverlay';
import { AuthProviderContainer } from './context/providerAccount';
import { unregister } from './registerServiceWorker';
import MasterRouter from './routers/MasterRouter';
import { initializeSentry } from './sentry';
import { customTheme } from './theme';

import '@fontsource/noto-sans-jp/400.css';
import '@fontsource/noto-sans-jp/500.css';
import '@fontsource/noto-sans-jp/700.css';
import { ErrorBoundary } from './ErrorBoundary';

window.global ||= window;

initializeSentry();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode> // `AuthUI instance is deleted!`というメッセージが多発する
  <BrowserRouter>
    <ChakraProvider
      cssVarsRoot="body"
      resetCSS={true}
      theme={customTheme}
      toastOptions={{
        defaultOptions: {
          isClosable: true,
        },
      }}
    >
      <ColorModeScript />
      <QueryClientProvider
        client={
          new QueryClient({
            defaultOptions: {
              queries: {
                // cacheTime: 1000 * 60, // 1min
                // staleTime: 1000 * 60, // 1min
                refetchOnWindowFocus: false,
                retry: false,
              },
            },
          })
        }
      >
        <AuthProviderContainer>
          <DialogContainer>
            <LoadingOverlayContextProvider>
              <ErrorBoundary>
                <MasterRouter />
              </ErrorBoundary>
            </LoadingOverlayContextProvider>
          </DialogContainer>
        </AuthProviderContainer>
        {/* {GLOBAL_CONFIG.ENV !== 'production' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )} */}
      </QueryClientProvider>
    </ChakraProvider>
  </BrowserRouter>
  // </React.StrictMode>,
);
unregister();
